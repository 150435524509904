body, textarea, input, button, select, option, .chat-container, ::placeholder {
  font-family: 'Vazir', Tahoma, sans-serif !important;
}

body {
  display: block;
  margin: 0px;
}

.message-right,
.message-left {
  padding: 8px 12px;
  margin: 2px;
  max-width: 70%;
  word-wrap: break-word;
  border-radius: 10px;
  display: table;
}

.message-right {
  align-self: flex-end;
  background-color: #c3e3fc; /* Light blue for user messages */
}

.message-left {
  align-self: flex-start;
  background-color: #e6e6e6; /* Light grey for assistant messages */
}

.chat-button:disabled {
  background-color: #cccccc; /* Grey */
  cursor: not-allowed;
}

.abort-button:disabled {
  background-color: #cccccc; /* Grey */
  cursor: not-allowed;
}

.chat-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  position: relative; /* Required for positioning children */
  padding-bottom: 60px; /* برای فضای فوتر */
}

.chat-form {
  display: flex;
  margin-bottom: 20px;
  margin-top: 10px;
  padding: 5px 10px;
  display: flex;
  bottom: 0; /* Stick to the bottom */
  max-width: 600px;
  width: -webkit-fill-available;
  position: sticky; /* Stick to the bottom */
}

.chat-input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  outline: none;
  resize: none;
  opacity: 95%;
}

.chat-button {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  outline: none;
}

.abort-button {
  margin-right: 10px;
  margin-left: 10px;
  padding: 5px 10;
  background-color: #ff0000;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  outline: none;
}

.chat-textarea {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  outline: none;
  flex: 1;
  overflow-y: scroll;
  display: contents;
}

body.dark-mode {
  background-color: #121212;
  color: #f0f0f0;
}

body.dark-mode .chat-container {
  background-color: #1e1e1e;
  border-color: #333;
}

body.dark-mode .chat-input {
  background-color: #2c2c2c;
  border-color: #555;
  color: #f0f0f0;
}

body.dark-mode .message-left {
  background-color: #333333;
  color: #f0f0f0;
}

body.dark-mode .message-right {
  background-color: #2a7fbb;
  color: #ffffff;
}

body.dark-mode .chat-button {
  background-color: #0056b3;
}

body.dark-mode .abort-button {
  background-color: #cc0000;
}

.sticky-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: inherit;
  padding: 8px 0;
  z-index: 999;
  font-family: 'Vazir', Tahoma, Ubuntu;
}

/*footer*/
.arad-footer {
  direction: rtl;
  text-align: center;
  font-size: 13px;
  margin-top: 10px;
  opacity: 0.75;
  color: #444;
}

body.dark-mode .arad-footer {
  color: #ccc;
}

.arad-footer a {
  color: inherit;
  text-decoration: none;
}

.arad-footer a:hover {
  text-decoration: underline;
}

/* ‌button colors */
/* وضعیت آزاد */
.chat-button.idle {
  background-color: #007bff; /* آبی */
  color: #ffffff;
  cursor: pointer;
}

/* وضعیت پاسخ‌دهی */
.chat-button.loading {
  background-color: #ffffff; /* سفید */
  color: #00aa55;           /* سبز لجنی ملایم */
  border: 1px solid #00aa55;
  cursor: not-allowed;
}

/* توقف: غیرفعال */
.abort-button.idle {
  background-color: #cccccc; /* خاکستری روشن */
  color: #ffffff;
  cursor: not-allowed;
}

/* توقف: فعال */
.chat-button,
.abort-button {
  transition: all 0.3s ease;
}
.chat-button.idle:hover {
  background-color: #0056b3; /* آبی تیره‌تر */
}

.chat-button.loading:hover {
  background-color: #f8f8f8; /* یه خاکستری خیلی روشن */
  color: #00aa55;
  border-color: #00aa55;
  cursor: not-allowed;
}
.abort-button.loading:hover {
  background-color: #cc0000; /* قرمز تیره‌تر */
}

.abort-button.idle:hover {
  background-color: #bbbbbb; /* خاکستری تیره‌تر */
  cursor: not-allowed;
}
body.dark-mode .chat-button.idle:hover {
  background-color: #3399ff;
}

body.dark-mode .chat-button.loading:hover {
  background-color: #2a2a2a;
}

body.dark-mode .abort-button.loading:hover {
  background-color: #e60000;
}



